import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const DeleteModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  message = (
    <>
      Are you sure you want to remove this record?
      <br />
      This action is not reversible.
    </>
  ),
  title = 'Confirm',
  icon = <i className="ri-delete-bin-2-line fs-1 text-danger" />,
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody>
        <div className="text-center">
          {icon}
          <div className="fs-15">
            <h4>{title}</h4>
            <p className="text-muted mb-0">{message}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <Button
            type="button"
            color="light"
            className="w-sm"
            onClick={onCloseClick}
          >
            No
          </Button>
          <Button
            type="button"
            color="danger"
            className="w-sm"
            id="delete-record"
            onClick={onDeleteClick}
          >
            Yes
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteModal;
