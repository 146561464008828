import { useMutation } from '@tanstack/react-query';
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

export const useToastMutation = ({
  loadingMessage = 'Loading',
  successMessage = 'Success',
  errorMessage = 'An unknown error occurred',
  ...mutationProps
}) => {
  const progressToast = useRef(null);
  return useMutation({
    ...mutationProps,
    onMutate: (variables) => {
      progressToast.current = toast(
        <div className="hstack">
          <span>{loadingMessage}</span>
          <Spinner color="info" size="sm" className="ms-auto" />
        </div>,
        {
          type: toast.TYPE.INFO,
          closeOnClick: false,
          draggable: false,
          autoClose: false,
        }
      );

      mutationProps.onMutate?.(variables);
    },
    onSuccess: (data, variables, context) => {
      if (successMessage) {
        let toastContent = successMessage;
        if (typeof successMessage === 'function') {
          toastContent = successMessage(data, variables, context);
        }
        toast(<>{toastContent}</>, { type: toast.TYPE.SUCCESS });
      }

      mutationProps.onSuccess?.(data, variables, context);
    },
    onError: (error, variables, context) => {
      if (errorMessage) {
        let toastContent = errorMessage;
        if (typeof errorMessage === 'function') {
          toastContent = errorMessage(error, variables, context);
        }
        toast(<>{toastContent}</>, {
          type: toast.TYPE.ERROR,
        });
      }

      mutationProps.onError?.(error, variables, context);
    },
    onSettled: (data, error, variables, context) => {
      toast.dismiss(progressToast.current);

      mutationProps.onSettled?.(data, error, variables, context);
    },
  });
};
