import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Router, RouterProvider } from '@tanstack/react-router';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import './App.css';
import { routeTree } from './routeTree';
import { APIClient } from './services/ApiClient';
import AuthService from './services/AuthService';
import { USERS } from './utils/UrlHelper';

import qs from 'qs';

const queryClient = new QueryClient();
const authService = new AuthService();
const apiClient = new APIClient();

const router = new Router({
  routeTree,
  context: {
    queryClient,
    authService,
  },
  defaultPreload: 'intent',
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
  stringifySearch: (searchObj) =>
    qs.stringify(searchObj, { encode: false, addQueryPrefix: true }),
  parseSearch: (searchString) =>
    qs.parse(searchString, { ignoreQueryPrefix: true }),
});

function App() {
  useEffect(() => {
    if (authService.getToken()) {
      apiClient
        .get(USERS + '/me')
        .then((res) => {
          authService.setUser(res);
        })
        .catch(() => {
          authService.logout();
          toast('Signed out: Invalid token. Please sign in again.', {
            type: toast.TYPE.ERROR,
          });
          router.navigate({ to: '/login' });
        });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar="true"
      />
    </QueryClientProvider>
  );
}

export default App;
