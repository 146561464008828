import { useQuery } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { APIClient } from '../../services/ApiClient';
import { ROOMS } from '../../utils/UrlHelper';
import Loader from '../common/Loader';

const apiClient = new APIClient();

const RoomsGridList = ({ enable = true, search = '', action }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 12,
  });

  const params = useSearch({
    strict: false,
  });

  const filterParams = useMemo(() => {
    const ret = { ...params };
    if (search) {
      ret.$or = [
        {
          sheep: {
            name: {
              $containsi: search,
            },
          },
        },
        {
          sheep: {
            phone: {
              $containsi: search,
            },
          },
        },
      ];
      const searchNum = Number(search);
      if (!Number.isNaN(searchNum)) {
        ret.$or.push({
          num: {
            $eq: search,
          },
        });
      }
    }
    setPagination((old) => ({
      page: 1,
      pageSize: old.pageSize,
    }));
    return ret;
  }, [params, search, setPagination]);

  const roomsQuery = useQuery({
    queryKey: [
      'rooms',
      'list',
      {
        pagination: { page: pagination.page, pageSize: pagination.pageSize },
        filters: {
          ...(filterParams ?? {}),
        },
        sort: 'num',
      },
    ],
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    enabled: enable && !!pagination,
    queryFn: () =>
      apiClient
        .get(ROOMS, {
          pagination: {
            page: pagination.page,
            pageSize: pagination.pageSize,
          },
          populate: {
            sheep: {
              fields: ['id', 'name', 'phone'],
            },
          },
          sort: 'num',
          ...{
            filters: {
              ...(filterParams ?? {}),
            },
          },
        })
        .then((res) => ({
          pagination: res.meta.pagination,
          rooms: res.data.map((e) => ({
            id: e.id,
            num: e.attributes.num,
            type: e.attributes.type,
            occupants: e.attributes.occupants,
            maxOccupants: e.attributes.maxOccupants,
            notes: e.attributes.notes,
            sheep: e.attributes.sheep?.data?.map((s) => ({
              value: s.id,
              label: s.attributes.name + ', ' + s.attributes.phone,
            })),
          })),
        })),
  });

  const roomColors = useMemo(() => {
    if (!roomsQuery.data?.rooms?.length) {
      return [];
    }
    return roomsQuery.data.rooms.map((r) => {
      if (r.occupants === r.maxOccupants) return 'success';
      if (r.occupants > r.maxOccupants) return 'danger';
      if (r.occupants > 0) return 'secondary';
      return 'info';
    });
  }, [roomsQuery.data]);

  const prevPage = useCallback(() => {
    setPagination((old) => ({ ...old, page: old.page - 1 }));
  }, [setPagination]);

  const canPrev = useMemo(() => pagination.page > 1, [pagination.page]);

  const nextPage = useCallback(() => {
    setPagination((old) => ({ ...old, page: old.page + 1 }));
  }, [setPagination]);

  const canNext = useMemo(
    () => pagination.page < (roomsQuery.data?.pagination?.pageCount ?? 1),
    [pagination.page, roomsQuery.data]
  );

  const onChangeInInput = useCallback(
    (event) => {
      const page = event.target.value ? Number(event.target.value) : 1;
      if (
        roomsQuery.data?.pagination &&
        roomsQuery.data.pagination.pageCount < page
      ) {
        return;
      }
      setPagination((old) => ({ ...old, page }));
    },
    [setPagination, roomsQuery.data]
  );

  return (
    <>
      {roomsQuery.data?.rooms?.length ? (
        <>
          <Row className="sheep-table overflow-y-scroll">
            {roomsQuery.data.rooms.map((item, key) => (
              <Col lg={4} sm={6} key={key} className="py-2">
                <Card>
                  <CardBody>
                    <div className="hstack gap-2">
                      <div className="text-center">
                        <div className="avatar">
                          <div
                            className={`avatar-title bg-${roomColors[key]}-subtle rounded`}
                          >
                            <i
                              className={`ri-home-line fs-2 text-${roomColors[key]}`}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="text-center flex-grow-1">
                        <h5 className="mb-1">
                          {item.num}
                          {item.type ? ` (${item.type})` : ''}
                        </h5>
                        <span className={`text-${roomColors[key]}`}>
                          {item.occupants}/{item.maxOccupants}
                        </span>
                      </div>
                      <Button
                        type="button"
                        color={roomColors[key]}
                        outline
                        onClick={() => {
                          if (typeof action === 'function') {
                            action(item);
                          }
                        }}
                      >
                        <i className="ri-pencil-line" />
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <div className="hstack">
            <div className="me-3 hstack gap-2">
              <Label
                htmlFor="pageSize"
                className="text-nowrap d-none d-md-block"
              >
                Show per page
              </Label>
              <Input
                type="select"
                className="w-auto"
                id="pageSize"
                name="pageSize"
                value={pagination.pageSize}
                onChange={(e) =>
                  setPagination({ page: 1, pageSize: e.target.value })
                }
              >
                <option value={12}>12</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
            </div>
            <div>
              <p className="mb-sm-0 text-muted">
                Showing{' '}
                <span className="fw-semibold">
                  {(pagination.page - 1) * pagination.pageSize + 1}
                </span>{' '}
                to{' '}
                <span className="fw-semibold">
                  {(pagination.page - 1) * pagination.pageSize +
                    roomsQuery.data.rooms.length}
                </span>{' '}
                of{' '}
                <span className="fw-semibold text-decoration-underline">
                  {roomsQuery.data.pagination.total}
                </span>{' '}
                entries
              </p>
            </div>
            <Row className="justify-content-md-end justify-content-center align-items-center p-2 ms-auto">
              <Col className="col-md-auto">
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    onClick={() => prevPage()}
                    disabled={!canPrev}
                  >
                    {'<'}
                  </Button>
                </div>
              </Col>
              <Col className="col-md-auto d-none d-md-block">
                Page{' '}
                <strong>
                  {pagination?.page ?? 1}
                  {roomsQuery.data?.pagination?.pageCount &&
                    ` of ${roomsQuery.data.pagination.pageCount}`}
                </strong>
              </Col>
              <Col className="col-md-auto">
                <Input
                  type="number"
                  min={1}
                  style={{ width: 70 }}
                  max={roomsQuery.data?.pagination?.pageCount ?? 1}
                  value={pagination?.page ?? 1}
                  onChange={onChangeInInput}
                />
              </Col>

              <Col className="col-md-auto">
                <div className="d-flex gap-1">
                  <Button
                    color="primary"
                    onClick={() => nextPage()}
                    disabled={!canNext}
                  >
                    {'>'}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </>
      ) : roomsQuery.isSuccess ? (
        <h6 className="text-center text-muted mt-4">No Rooms found</h6>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default RoomsGridList;
