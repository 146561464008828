import { useFormik } from 'formik';
import {
  AsyncPaginate,
  reduceGroupedOptions,
} from 'react-select-async-paginate';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import { loadSheepOptions } from '../../services/OptionLoaders';
import { useMemo } from 'react';

const RoomForm = ({ initialRoom, onSubmit }) => {
  const initialValues = useMemo(
    () => ({ ...initialRoom, type: initialRoom?.type ?? 'Male' }),
    [initialRoom]
  );
  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values) => {
      if (typeof onSubmit === 'function') {
        if (typeof values.notes === 'string')
          values.notes = values.notes.replace(/[‪‬]/g, '').trim();
        if (values.sheep.length > 0) {
          values.sheep = values.sheep.map((o) => o.value);
          if (values.sheep === '') values.sheep = null;
        }
        onSubmit(values);
      }
    },
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col className="mb-2" md="4" xs="6">
          <Label htmlFor="num">Room Number</Label>
          <Input
            id="num"
            name="num"
            type="number"
            value={initialValues.num}
            readOnly
          />
        </Col>
        <Col className="mb-2" md="4" xs="6">
          <Label htmlFor="maxOccupants">Max Occupants</Label>
          <Input
            id="maxOccupants"
            name="maxOccupants"
            type="number"
            value={initialValues.maxOccupants}
            readOnly
          />
        </Col>
        <Col className="mb-2" md="4">
          <Label htmlFor="type">Room Type</Label>
          <Input
            id="type"
            name="type"
            type="select"
            value={validation.values.type}
            onChange={(e) => {
              validation.setFieldValue('sheep', []);
              return validation.handleChange(e);
            }}
            onBlur={validation.handleBlur}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Family">Family</option>
          </Input>
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <Label htmlFor="sheep">Sheep</Label>
          <AsyncPaginate
            key={`${validation.values.type}SheepSelect`}
            value={validation.values.sheep}
            isMulti
            name="sheep"
            inputId="sheep"
            placeholder="Select sheep"
            onChange={(option) => validation.setFieldValue('sheep', option)}
            debounceTimeout={300}
            additional={{
              page: 1,
              gender:
                validation.values.type !== 'Family'
                  ? validation.values.type
                  : undefined,
            }}
            loadOptions={loadSheepOptions}
            reduceOptions={reduceGroupedOptions}
            isOptionDisabled={(o) =>
              o.isDisabled ||
              validation.values.sheep?.length >= initialValues.maxOccupants ||
              (o.roomNum && o.roomNum !== initialValues.num)
            }
            styles={{
              option: (styles, o) =>
                o.value === ''
                  ? {
                      ...styles,
                      fontStyle: 'italic',
                      color: 'var(--bs-gray-500)',
                    }
                  : o.isDisabled
                  ? {
                      ...styles,
                      color: 'var(--bs-danger)',
                      backgroundColor: 'var(--bs-gray-200)',
                    }
                  : styles,
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <Label htmlFor="notes">Notes</Label>
          <Input
            id="notes"
            name="notes"
            type="textarea"
            rows="3"
            placeholder="Notes/comments"
            value={validation.values.notes}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
        </Col>
      </Row>
      <div>
        <Button type="submit" color="success">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default RoomForm;
