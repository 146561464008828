import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSearch } from '@tanstack/react-router';
import chroma from 'chroma-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, FormGroup, Input } from 'reactstrap';
import { APIClient } from '../../services/ApiClient';
import { SHEEP } from '../../utils/UrlHelper';
import Loader from '../common/Loader';
import TableContainer from '../common/TableContainer';

const apiClient = new APIClient();

const SheepTable = ({
  actionsCol,
  serverFilters,
  enable = true,
  extraColumns,
  className = '',
  columnFilters,
  search,
}) => {
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 12,
  });
  const [sorting, setSorting] = useState(null);

  const queryClient = useQueryClient();

  const formatPhone = useCallback((phone) => {
    if (phone.startsWith('+')) return phone.substring(1);
    if (!phone.startsWith('2')) return '2' + phone;
    return phone;
  }, []);

  const params = useSearch({
    strict: false,
  });

  const filterParams = useMemo(() => {
    const searchParams = { ...params };
    if (params.room) {
      searchParams.room = {
        num: params.room,
      };
    }
    if (params.tags) {
      searchParams.tags = {
        id: params.tags,
      };
    }
    setPagination((old) => ({
      page: 1,
      pageSize: old.pageSize,
    }));
    if (search)
      return {
        $and: [
          {
            $or: [
              { name: { $containsi: search } },
              { phone: { $containsi: search } },
            ],
          },
          { ...searchParams },
        ],
      };
    else return searchParams;
  }, [params, search, setPagination]);

  useEffect(() => {
    setPagination((old) => ({
      page: 1,
      pageSize: old.pageSize,
    }));
  }, [serverFilters, setPagination]);

  const { mutate: toggleBusCheck } = useMutation({
    mutationFn: (sheep) => {
      if (!sheep) {
        return false;
      }
      return apiClient.put(`${SHEEP}/${sheep.id}`, {
        data: {
          id: sheep.id,
          boardedBus: sheep.boardedBus,
        },
      });
    },
    onMutate: async (sheep) => {
      const queryKey = [
        'sheep',
        'list',
        {
          pagination: { page: pagination.page, pageSize: pagination.pageSize },
          filters: {
            ...(serverFilters ?? {}),
            ...(filterParams ?? {}),
          },
          sort: sorting ?? 'createdAt:desc',
        },
      ];
      await queryClient.cancelQueries({
        queryKey,
      });

      const previousData = queryClient.getQueryData(queryKey);

      queryClient.setQueryData(queryKey, (old) => {
        const sheepIndex = old.sheep?.findIndex((v) => v.id === sheep.id);
        if (!sheepIndex || sheepIndex === -1) return old;
        const newSheep = [...old.sheep];
        newSheep[sheepIndex] = sheep;
        return { ...old, sheep: newSheep };
      });

      return { previousData, queryKey };
    },
    onError: (err, sheep, context) => {
      queryClient.setQueryData(context?.queryKey, context?.previousData);
    },
    onSettled: (data, sheep) => {
      queryClient.invalidateQueries(['sheep', 'list']);
    },
  });

  const sheepQuery = useQuery({
    queryKey: [
      'sheep',
      'list',
      {
        pagination: { page: pagination.page, pageSize: pagination.pageSize },
        filters: {
          ...(serverFilters ?? {}),
          ...(filterParams ?? {}),
        },
        sort: sorting ?? 'createdAt:desc',
      },
    ],
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    enabled: enable && !!pagination,
    queryFn: () =>
      apiClient
        .get(SHEEP, {
          pagination: {
            page: pagination.page,
            pageSize: pagination.pageSize,
          },
          populate: {
            room: {
              fields: ['id', 'num'],
            },
            tags: true,
          },
          sort: sorting ?? 'createdAt:desc',
          ...{
            filters: {
              ...(serverFilters ?? {}),
              ...(filterParams ?? {}),
            },
          },
        })
        .then((res) => ({
          pagination: res.meta.pagination,
          sheep: res.data.map((e) => ({
            id: e.id,
            createdAt: e.attributes.createdAt,
            name: e.attributes.name,
            phone: e.attributes.phone,
            gender: e.attributes.gender,
            amountPaid: e.attributes.amountPaid,
            room: e.attributes.room?.data
              ? {
                  value: e.attributes.room.data.id,
                  label: e.attributes.room.data.attributes.num,
                }
              : null,
            notes: e.attributes.notes,
            confirmed: e.attributes.confirmed,
            includePaymentInTotal: e.attributes.includePaymentInTotal,
            transport: e.attributes.transport,
            boardedBus: e.attributes.boardedBus,
            tags: e.attributes.tags.data.map((t) => ({
              value: t.id,
              label: t.attributes.label,
              color: t.attributes.color,
            })),
          })),
        })),
  });

  useEffect(() => {
    if (sheepQuery.data) {
      setPagination(sheepQuery.data.pagination);
    }
  }, [sheepQuery.data, setPagination]);

  // Column
  const columns = useMemo(() => {
    const ret = [
      {
        header: 'Bus',
        accessorKey: 'boardedBus',
        id: 'boardedBus',
        cell: (cellProps) => {
          return (
            <FormGroup switch>
              <Input
                type="switch"
                role="switch"
                id={`switch-bus-${cellProps.row.original.id}`}
                checked={cellProps.row.original.boardedBus}
                onChange={(event) => {
                  const newSheep = { ...cellProps.row.original };
                  newSheep.boardedBus = event.target.checked;
                  toggleBusCheck(newSheep);
                }}
              />
            </FormGroup>
          );
        },
      },
      {
        header: 'Name',
        accessorKey: 'name',
        id: 'name',
      },
      {
        header: 'Phone',
        id: 'phone',
        accessorFn: (sheep) => sheep.phone,
        cell: (cellProps) => (
          <>
            <a href={`tel:${cellProps.row.original.phone}`} className="me-1">
              {cellProps.row.original.phone}
            </a>
            <a
              href={
                'https://wa.me/' + formatPhone(cellProps.row.original.phone)
              }
            >
              <Button size="sm" color="success" outline>
                <i className="ri-whatsapp-line" />
              </Button>
            </a>
          </>
        ),
      },
      {
        header: 'Gender',
        accessorKey: 'gender',
        id: 'gender',
      },
      {
        header: 'Amount Paid',
        accessorKey: 'amountPaid',
        id: 'amountPaid',
        cell: (cellProps) =>
          !cellProps.row.original.confirmed ? (
            cellProps.row.original.amountPaid
          ) : (
            <Badge color="success" pill>
              FULFILLED
            </Badge>
          ),
      },
      {
        header: 'Room',
        id: 'room',
        accessorFn: (sheep) => sheep.room?.label ?? '-',
        enableSorting: false,
      },
      {
        header: 'Transportation',
        accessorKey: 'transport',
      },
      {
        header: 'Tags',
        id: 'tags',
        enableSorting: false,
        accessorKey: 'tags',
        cell: (cellProps) =>
          !Array.isArray(cellProps.row.original.tags) ||
          cellProps.row.original.tags.length === 0 ? (
            <>-</>
          ) : (
            cellProps.row.original.tags.map((t, i) => {
              const color = t.color ? chroma(t.color) : null;
              return (
                <span
                  className={`badge me-1${
                    !color ? ' bg-dark-subtle text-dark' : ''
                  }`}
                  style={
                    color
                      ? {
                          backgroundColor: color.alpha(0.1).css(),
                          color: t.color,
                        }
                      : undefined
                  }
                  key={`sheep.${cellProps.row.original.id}.tags.${i}`}
                >
                  {t.label}
                </span>
              );
            })
          ),
      },
      {
        header: 'Registration Date',
        id: 'createdAt',
        accessorFn: (sheep) =>
          new Date(sheep.createdAt).toLocaleString('en-GB', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }),
      },
      ...(actionsCol ? [actionsCol] : []),
    ];
    if (
      extraColumns &&
      extraColumns.columns &&
      extraColumns.columns.length !== 0
    ) {
      ret.splice(
        extraColumns.pos ?? ret.length - 1,
        0,
        ...extraColumns.columns
      );
    }
    return ret;
  }, [actionsCol, extraColumns, formatPhone]);

  return (
    <div className={className}>
      {sheepQuery.data?.sheep?.length ? (
        <TableContainer
          columns={columns}
          data={sheepQuery.data.sheep || []}
          divClass="table-responsive table-card mb-2 sheep-table"
          tableClass="align-middle table-nowrap"
          theadClass="table-light"
          pagination={pagination}
          setPagination={setPagination}
          sorting={sorting}
          setSorting={setSorting}
          columnFilters={columnFilters}
        />
      ) : sheepQuery.isSuccess ? (
        <h6 className="text-center text-muted mt-4">No sheep found</h6>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default SheepTable;
