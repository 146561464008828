import { Outlet, useNavigate } from '@tanstack/react-router';
import { useState } from 'react';
import { Button } from 'reactstrap';
import SideBar from '../components/SideBar';
import AuthService from '../services/AuthService';
import { toast } from 'react-toastify';

const authService = new AuthService();

const AdminLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(
    window.innerWidth > 576 ? true : false
  );
  const toggleSidebar = () => setSidebarOpen((old) => !old);
  const navigate = useNavigate();

  return (
    <div className="admin-layout wrapper">
      <SideBar isOpen={sidebarOpen} toggle={toggleSidebar} />

      <div className={`content${sidebarOpen ? ' is-open' : ''}`}>
        <div className="hstack">
          <Button color="light" onClick={toggleSidebar} className="mb-3">
            <i className="ri-menu-5-line" />
          </Button>
          <Button
            color="danger"
            className="ms-auto"
            outline
            onClick={() => {
              authService.logout();
              navigate({
                to: '/login',
              }).then(() =>
                toast('Signed Out', {
                  type: toast.TYPE.INFO,
                })
              );
            }}
          >
            Sign Out
          </Button>
        </div>

        <hr />

        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
