import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { Button, Col, Input, Label, Row, Table } from 'reactstrap';

const TableContainer = ({
  columns,
  data,
  pagination,
  setPagination,
  sorting,
  setSorting,
  divClass,
  tableClass,
  theadClass,
  noPagination = false,
  columnFilters,
}) => {
  const table = useReactTable({
    data: data ?? [],
    columns,
    pageCount: pagination?.pageCount ?? -1,
    state: {
      pagination: {
        pageIndex: pagination?.page ? pagination.page - 1 : 0,
        pageSize: pagination?.pageSize ?? 12,
      },
      sorting: !sorting
        ? []
        : [
            {
              id: sorting.split(':')[0],
              desc: sorting.split(':')[1] === 'desc',
            },
          ],
    },
    onSortingChange: (updater) => {
      setPagination((old) => ({ ...old, page: 1 }));
      let oldVal = [];
      if (sorting) {
        const a = sorting.split(':');
        oldVal = [
          {
            id: a[0],
            desc: a[1] === 'desc',
          },
        ];
      }
      let newVal = updater(oldVal);
      newVal =
        newVal.length === 0
          ? null
          : newVal[0].id + (newVal[0].desc ? ':desc' : ':asc');
      setSorting(newVal);
    },
    enableSorting: !!setSorting,
    onPaginationChange: (paginationUpdater) => {
      const newVal = paginationUpdater({
        pageIndex: pagination.page - 1,
        pageSize: pagination.pageSize,
      });
      setPagination((old) => {
        const newPagination = { ...old };
        newPagination.page = newVal.pageIndex + 1;
        newPagination.pageSize = newVal.pageSize;
        return newPagination;
      });
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true,
    manualSorting: true,
  });
  const [prevFilters, setPrevFilters] = useState();
  if (columnFilters !== prevFilters) {
    setPrevFilters(columnFilters);
    table.setColumnFilters(columnFilters);
  }

  const onChangeInInput = (event) => {
    const page = event.target.value ? Number(event.target.value) : 1;
    table.setPageIndex(page - 1);
  };

  return (
    <React.Fragment>
      <div className={divClass}>
        <Table hover className={tableClass}>
          <thead className={theadClass}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'pe-always'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: (
                              <i className="ri-arrow-up-s-fill text-secondary" />
                            ),
                            desc: (
                              <i className="ri-arrow-down-s-fill text-secondary" />
                            ),
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {!noPagination && (
        <div className="hstack">
          <div>
            <div className="ms-auto me-3 hstack gap-2">
              <Label
                htmlFor="pageSize"
                className="text-nowrap d-none d-md-block"
              >
                Show per page
              </Label>
              <Input
                type="select"
                className="w-auto"
                id="pageSize"
                name="pageSize"
                value={pagination.pageSize}
                onChange={(e) => table.setPageSize(e.target.value)}
              >
                <option value={12}>12</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={75}>75</option>
                <option value={100}>100</option>
              </Input>
            </div>
          </div>
          <div>
            <p className="mb-sm-0 text-muted">
              Showing{' '}
              <span className="fw-semibold">
                {(pagination.page - 1) * pagination.pageSize + 1}
              </span>{' '}
              to{' '}
              <span className="fw-semibold">
                {(pagination.page - 1) * pagination.pageSize + data.length}
              </span>{' '}
              of{' '}
              <span className="fw-semibold text-decoration-underline">
                {pagination.total}
              </span>{' '}
              entries
            </p>
          </div>
          <Row className="justify-content-md-end justify-content-center align-items-center p-2 ms-auto">
            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  {'<'}
                </Button>
              </div>
            </Col>
            <Col className="col-md-auto d-none d-md-block">
              Page{' '}
              <strong>
                {pagination?.page ?? 1}{' '}
                {pagination?.pageCount && `of ${pagination.pageCount}`}
              </strong>
            </Col>
            <Col className="col-md-auto">
              <Input
                type="number"
                min={1}
                style={{ width: 70 }}
                max={pagination?.pageCount ?? 1}
                value={pagination?.page ?? 1}
                onChange={onChangeInInput}
              />
            </Col>

            <Col className="col-md-auto">
              <div className="d-flex gap-1">
                <Button
                  color="primary"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  {'>'}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}
    </React.Fragment>
  );
};

export default TableContainer;
