import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { Button, Form, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import FilterInputs from './FilterInputs';
import { CONDITION_LABELS, FieldType } from './Filterables';

const mapConditions = (filter) => {
  return (filter?.conditions?.() ?? []).map((v) => ({
    value: v,
    label: CONDITION_LABELS[v],
  }));
};

const FilterPopover = ({
  show = false,
  filters = [],
  close = undefined,
  onAdd = undefined,
}) => {
  const [selectedFilter, setSelectedFilter] = useState(filters[0]);
  const [conditionOptions, setConditionOptions] = useState(
    mapConditions(filters[0])
  );
  const [selectedCondition, setSelectedCondition] = useState(
    conditionOptions[0]
  );

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      const t = event.currentTarget.elements;
      const res = {
        key: t.field.value,
        label: selectedFilter.label,
        condition: t.condition.value,
        value: t.value2?.value
          ? [t.value?.value ?? 0, t.value2.value]
          : t.value?.value ?? true,
        valueLabel: t.valueLabel?.value,
      };
      if (
        res.condition === '$between' &&
        selectedFilter?.fieldType === FieldType.DATE
      ) {
        res.value = res.value.split(' to ');
      }
      onAdd?.(res);
    },
    [onAdd, selectedFilter]
  );

  return (
    <Popover
      target="filter-button"
      isOpen={show}
      placement="bottom"
      style={{ width: '276px' }}
    >
      <PopoverHeader className="hstack gap-2">
        Add Filter
        <Button
          type="button"
          size="sm"
          className="btn-close ms-auto"
          onClick={() => {
            close?.();
          }}
          aria-label="Close"
        ></Button>
      </PopoverHeader>
      <PopoverBody>
        <Form className="vstack gap-2" onSubmit={onSubmit}>
          <Select
            name="field"
            placeholder="Select field"
            isSearchable={false}
            options={filters}
            value={selectedFilter}
            getOptionValue={(option) => option.key}
            getOptionLabel={(option) => option.label}
            onChange={(v) => {
              const filter = v;
              setSelectedFilter(filter);
              const mappedConditions = mapConditions(filter);
              setConditionOptions(mappedConditions);
              setSelectedCondition(mappedConditions[0]);
            }}
          />
          {selectedFilter && (
            <>
              <Select
                name="condition"
                placeholder="Select condition"
                required
                isSearchable={false}
                options={conditionOptions}
                value={selectedCondition}
                onChange={(o) => setSelectedCondition(o)}
              />
              <FilterInputs
                filter={selectedFilter}
                condition={selectedCondition.value}
              />
              <Button
                color="secondary"
                id="filter-button"
                outline
                type="submit"
              >
                <i className="ri-add-line fw-semibold align-bottom me-1" />
                Add Filter
              </Button>
            </>
          )}
        </Form>
      </PopoverBody>
    </Popover>
  );
};

export default FilterPopover;
