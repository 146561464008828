import { useNavigate, useSearch } from '@tanstack/react-router';
import { useCallback, useMemo } from 'react';
import { Button } from 'reactstrap';
import { CONDITION_LABELS } from './Filterables';

const FilterList = ({ filters, filterLabels }) => {
  const params = useSearch({ strict: false });

  const navigate = useNavigate();

  const removeParam = useCallback(
    (key) => {
      navigate({
        search: (prev) => {
          const newSearch = { ...prev };
          delete newSearch[key];
          return newSearch;
        },
      });
    },
    [navigate]
  );

  const appliedFilters = useMemo(
    () =>
      !params
        ? []
        : Object.entries(params).map(([key, content]) => {
            const filter = filters.find((f) => f.key === key);
            const label = filter?.label ?? key;
            const filterObj = content;
            const condition = Object.keys(filterObj)[0];
            const value = filterObj[condition];
            let valueLabel = filterLabels[key];
            let showValue = true;
            if (condition === '$null' || condition === '$notNull')
              showValue = false;
            else if (Array.isArray(value)) valueLabel = value.join(' and ');
            return {
              key,
              condition,
              label,
              value,
              valueLabel,
              showValue,
            };
          }),
    [filters, filterLabels, params]
  );

  return (
    <div className="hstack gap-1 flex-wrap">
      {appliedFilters.map((f, i) => (
        <Button
          key={i}
          color="secondary"
          size="sm"
          outline
          className="right rounded-pill"
          onClick={() => removeParam(f.key)}
        >
          <i className="ri-close-line rounded-pill ms-2" /> {f.label}{' '}
          {CONDITION_LABELS[f.condition]}
          {f.showValue && ` "${f.valueLabel ?? f.value}"`}
        </Button>
      ))}
    </div>
  );
};

export default FilterList;
