import CountUp from 'react-countup';
import { Card, CardBody } from 'reactstrap';

const NumberCard = ({
  title = '',
  suffix = '',
  value,
  color = 'primary',
  icon = 'ri-information-line',
}) => {
  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between">
          <div>
            <p className="fw-medium mb-0 text-muted">{title}</p>
            <h2 className="mt-4 fw-semibold">
              <CountUp
                start={0}
                suffix={suffix}
                end={value}
                duration={3}
              />
            </h2>
          </div>
          <div>
            <div className="avatar flex-shrink-0">
              <span
                className={`avatar-title rounded-circle fs-2 bg-${color}-subtle`}
              >
                <i className={`${icon} fs-3 text-${color}`} />
              </span>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default NumberCard;
