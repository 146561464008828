export let FieldType;
(function (FieldType) {
  FieldType[(FieldType['TEXT'] = 0)] = 'TEXT';
  FieldType[(FieldType['CHOICE'] = 1)] = 'CHOICE';
  FieldType[(FieldType['NUMBER'] = 2)] = 'NUMBER';
  FieldType[(FieldType['ASYNC_CHOICE'] = 3)] = 'ASYNC_CHOICE';
  FieldType[(FieldType['DATE'] = 4)] = 'DATE';
})(FieldType || (FieldType = {}));

export const CONDITION_LABELS = {
  $containsi: 'contains',
  $notContainsi: 'does not contain',
  $eqi: 'is',
  $eq: 'is',
  $nei: 'is not',
  $ne: 'is not',
  $lt: 'less than',
  $lte: 'less than or equal to',
  $gt: 'greater than',
  $gte: 'greater than or equal to',
  $null: 'is null',
  $notNull: 'is not null',
  $between: 'is between',
};

class AbstractFieldFilter {
  constructor(key, label, conditions, fieldType) {
    this.key = key;
    this.label = label;
    this.conditions = conditions;
    this.fieldType = fieldType;
  }
}

class TextFilter extends AbstractFieldFilter {
  static TEXT_CONSTRAINTS = [
    '$containsi',
    '$notContainsi',
    '$eqi',
    '$nei',
    '$null',
    '$notNull',
  ];

  constructor({ key, label, fieldType = FieldType.TEXT }) {
    super(key, label, () => TextFilter.TEXT_CONSTRAINTS, fieldType);
  }
}

class ChoiceFilter extends AbstractFieldFilter {
  static CHOICE_CONSTRAINTS = ['$eq', '$ne', '$null', '$notNull'];

  constructor({
    key,
    label,
    choices,
    fieldType = FieldType.CHOICE,
    styles = undefined,
  }) {
    super(key, label, () => ChoiceFilter.CHOICE_CONSTRAINTS, fieldType);
    this.choices = choices;
    this.styles = styles;
  }
}

class AsyncChoiceFilter extends AbstractFieldFilter {
  constructor({
    key,
    label,
    loadOptionsFn,
    fieldType = FieldType.ASYNC_CHOICE,
    styles = undefined,
  }) {
    super(key, label, () => ChoiceFilter.CHOICE_CONSTRAINTS, fieldType);
    this.loadOptionsFn = loadOptionsFn;
    this.styles = styles;
  }
}

class NumberFilter extends AbstractFieldFilter {
  static NUMBER_CONSTRAINTS = [
    '$eq',
    '$ne',
    '$between',
    '$lt',
    '$lte',
    '$gt',
    '$gte',
    '$null',
    '$notNull',
  ];
  constructor({ key, label, fieldType = FieldType.NUMBER }) {
    super(key, label, () => NumberFilter.NUMBER_CONSTRAINTS, fieldType);
  }
}

class DateFilter extends AbstractFieldFilter {
  static DATE_CONSTRAINTS = [
    '$eq',
    '$ne',
    '$between',
    '$lt',
    '$lte',
    '$gt',
    '$gte',
    '$null',
    '$notNull',
  ];

  constructor({ key, label, fieldType = FieldType.DATE }) {
    super(key, label, () => DateFilter.DATE_CONSTRAINTS, fieldType);
  }
}

export { ChoiceFilter, TextFilter, NumberFilter, AsyncChoiceFilter, DateFilter };
