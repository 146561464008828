import { Outlet } from '@tanstack/react-router';
import dpFull from '../assets/img/dp-full.jpg';

const AuthLayout = () => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center bg-primary position-absolute z-n1 w-100 h-50 border-bottom rounded-bottom-circle">
        <img src={dpFull} height="300" alt="Logo" />
      </div>
      <Outlet />
    </div>
  );
};

export default AuthLayout;
