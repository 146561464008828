import { useNavigate } from '@tanstack/react-router';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { useToastMutation } from '../hooks/useToastMutation';
import AuthService from '../services/AuthService';

const authService = new AuthService();

const Login = () => {
  const navigate = useNavigate();

  const { mutate: handleLogin, isPending } = useToastMutation({
    mutationFn: (values) => authService.login(values.username, values.password),
    successMessage: 'Signed in',
    errorMessage: (e) => {
      if (e.response?.status === 400 || e.response?.status === 401 || e.response?.status === 403) {
        return 'Invalid credentials';
      }
      return 'Unknown error occurred';
    },
    onSuccess: () => {
      navigate({
        to: '/dashboard',
      });
    },
  });

  const validation = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: handleLogin,
  });

  return (
    <Container className="vh-100 vstack justify-content-center">
      <Row className="justify-content-center">
        <Col md="8" lg="6" xl="5">
          <Card>
            <CardBody>
              <Form>
                <div className="mb-3">
                  <Label htmlFor="username">Username</Label>
                  <Input
                    type="text"
                    placeholder="Enter username"
                    id="username"
                    name="username"
                    value={validation.values.username}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.username &&
                      !!validation.errors.username
                    }
                  />
                  <FormFeedback type="invalid">
                    {validation.errors.username}
                  </FormFeedback>
                </div>
                <div className="mb-3">
                  <Label htmlFor="password">Password</Label>
                  <Input
                    type="password"
                    placeholder="Enter password"
                    id="password"
                    name="password"
                    value={validation.values.password}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.password &&
                      !!validation.errors.password
                    }
                  />
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                </div>
                <Button
                  type="submit"
                  color="primary"
                  className="w-100 text-white fw-bold"
                  disabled={isPending}
                  onClick={(e) => {
                    e.preventDefault();
                    validation.submitForm();
                  }}
                >
                  Sign In
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
