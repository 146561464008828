import { Route, redirect } from '@tanstack/react-router';
import AdminLayout from './layouts/AdminLayout';
import AuthLayout from './layouts/AuthLayout';
import { Route as rootRoute } from './routes/root';
import Dashboard from './views/Dashboard';
import Login from './views/Login';
import SheepList from './views/SheepList';
import RoomsList from './views/RoomsList';

const authRoute = new Route({
  component: AuthLayout,
  id: '/_auth',
  getParentRoute: () => rootRoute,
});

const adminRoute = new Route({
  component: AdminLayout,
  id: '/_admin',
  getParentRoute: () => rootRoute,
  beforeLoad: ({ context }) => {
    const token = context.authService?.getToken();
    if (!token) {
      throw redirect({
        to: '/login',
      });
    }
  },
});

export const routeTree = rootRoute.addChildren([
  authRoute.addChildren([
    new Route({
      component: Login,
      path: '/login',
      getParentRoute: () => authRoute,
      beforeLoad: ({ context }) => {
        const token = context.authService?.getToken();
        if (token) {
          throw redirect({
            to: '/dashboard',
          });
        }
      },
    }),
  ]),
  adminRoute.addChildren([
    new Route({
      component: Dashboard,
      path: '/dashboard',
      getParentRoute: () => adminRoute,
    }),
    new Route({
      component: SheepList,
      path: '/registration',
      getParentRoute: () => adminRoute,
    }),
    new Route({
      component: RoomsList,
      path: '/rooms',
      getParentRoute: () => adminRoute,
    }),
  ]),
  new Route({
    path: '/*',
    getParentRoute: () => rootRoute,
    beforeLoad: () => {
      throw redirect({
        to: '/login',
      });
    },
  }),
  new Route({
    path: '/',
    getParentRoute: () => rootRoute,
    beforeLoad: () => {
      throw redirect({
        to: '/login',
      });
    },
  }),
]);
