import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Filter from '../components/filter/Filter';
import { FieldType } from '../components/filter/Filterables';
import RoomForm from '../components/rooms/RoomForm';
import { useToastMutation } from '../hooks/useToastMutation';
import { APIClient } from '../services/ApiClient';
import { ROOMS } from '../utils/UrlHelper';
import RoomsGridList from '../components/rooms/RoomsGridList';

const apiClient = new APIClient();

const ROOMS_FILTERS = Object.freeze([
  { key: 'num', label: 'Room Number', fieldType: FieldType.NUMBER },
  {
    key: 'type',
    label: 'Room Type',
    fieldType: FieldType.CHOICE,
    choices: ['Male', 'Female', 'Family'],
  },
  {
    key: 'occupants',
    label: 'Number of Occupants',
    fieldType: FieldType.NUMBER,
  },
  { key: 'maxOccupants', label: 'Max Occupants', fieldType: FieldType.NUMBER },
]);

const RoomsList = () => {
  const [formModal, setFormModal] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [search, setSearch] = useState('');

  const toggleModal = useCallback(
    () => setFormModal((old) => !old),
    [setFormModal]
  );

  const queryClient = useQueryClient();

  const { mutate: handleSubmit } = useToastMutation({
    mutationFn: (room) => {
      if (!room) {
        return false;
      }
      return apiClient.put(`${ROOMS}/${room.id}`, {
        data: room,
      });
    },
    onSuccess: (data, room) => {
      queryClient.invalidateQueries(['rooms', 'list']);
      if (room.id) {
        queryClient.invalidateQueries(['rooms', 'details', room.id]);
      }
    },
    onSettled: () => {
      setSelectedRoom(undefined);
    },
  });

  const editRoom = useCallback(
    (room) => {
      setSelectedRoom(room);
      setFormModal(true);
    },
    [setSelectedRoom, setFormModal]
  );
  return (
    <>
      <div className="hstack gap-3 mb-3">
        <div className="search-box">
          <Input
            type="text"
            size={26}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control search bg-light border-light"
            id="searchRooms"
            placeholder="Search by sheep or room num."
          />
          <i className="ri-search-line search-icon"></i>
        </div>
        <Filter filters={ROOMS_FILTERS} />
      </div>
      <RoomsGridList search={search} action={editRoom} />
      <Modal size="xl" isOpen={formModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Edit Room</ModalHeader>
        <ModalBody>
          <RoomForm
            onSubmit={(room) => {
              toggleModal();
              handleSubmit(room);
            }}
            initialRoom={selectedRoom}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default RoomsList;
