import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Loader from '../components/common/Loader';
import NumberCard from '../components/dashboard/NumberCard';
import { APIClient } from '../services/ApiClient';
import { SUMMARY } from '../utils/UrlHelper';

const apiClient = new APIClient();

const Dashboard = () => {
  const { isLoading, data } = useQuery({
    queryKey: ['summary'],
    staleTime: 5 * 60 * 1000,
    queryFn: () => apiClient.get(SUMMARY).then((res) => res.data.attributes),
  });

  const summary = useMemo(
    () =>
      !data
        ? null
        : {
            ...data,
            unassignedRooms:
              data.totalRooms -
              data.maleRooms -
              data.femaleRooms -
              data.familyRooms,
            partiallyFilledRooms:
              data.totalRooms - data.fullRooms - data.emptyRooms,
            deficit: data.totalDue - data.totalPaid,
          },
    [data]
  );

  return (
    <Container fluid>
      {(isLoading && <Loader />) ||
        (summary && (
          <>
            <Row>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="TOTAL SHEEP"
                  value={summary.totalSheep}
                  icon="ri-team-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="ROOMS CAPACITY"
                  value={summary.roomsTotalCapacity}
                  icon="ri-community-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="SHEEP IN BUSES"
                  value={summary.totalBusSheep}
                  color="info"
                  icon="ri-bus-2-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="SHEEP IN CARS"
                  value={summary.totalCarSheep}
                  color="dark"
                  icon="ri-car-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <Card>
                  <CardBody>
                    <p className="fw-medium mb-0 text-muted">SHEEP GENDER</p>
                    <ReactApexChart
                      series={[
                        summary.totalMale ?? 0,
                        summary.totalFemale ?? 0,
                      ]}
                      type="pie"
                      height={300}
                      options={{
                        labels: ['Male', 'Female'],
                        legend: {
                          position: 'bottom',
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <Card>
                  <CardBody>
                    <p className="fw-medium mb-0 text-muted">SHEEP IN ROOMS</p>
                    <ReactApexChart
                      series={[
                        summary.totalSheepInRooms ?? 0,
                        summary.totalSheepNoRoom ?? 0,
                        summary.vacancies ?? 0,
                      ]}
                      type="pie"
                      height={300}
                      options={{
                        labels: ['In Room', 'No Room', 'Vacancies'],
                        legend: {
                          position: 'bottom',
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <Card>
                  <CardBody>
                    <p className="fw-medium mb-0 text-muted">ROOM TYPE</p>
                    <ReactApexChart
                      height={300}
                      type="donut"
                      series={[
                        summary.maleRooms ?? 0,
                        summary.femaleRooms ?? 0,
                        summary.familyRooms ?? 0,
                        summary.unassignedRooms ?? 0,
                      ]}
                      options={{
                        labels: ['Male', 'Female', 'Family', 'Unassigned'],
                        legend: {
                          position: 'bottom',
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <Card>
                  <CardBody>
                    <p className="fw-medium mb-0 text-muted">
                      ROOM AVAILABILITY
                    </p>
                    <ReactApexChart
                      height={300}
                      type="donut"
                      series={[
                        summary.fullRooms ?? 0,
                        summary.partiallyFilledRooms ?? 0,
                        summary.emptyRooms ?? 0,
                      ]}
                      options={{
                        chart: {
                          type: 'donut',
                        },
                        labels: ['Full', 'Partially Filled', 'Empty'],
                        legend: {
                          position: 'bottom',
                        },
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="TOTAL COST"
                  suffix=" EGP"
                  value={summary.totalDue}
                  color="primary"
                  icon="ri-money-pound-circle-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="PAID"
                  suffix=" EGP"
                  value={summary.totalPaid}
                  color="success"
                  icon="ri-cash-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="DEFICIT"
                  suffix=" EGP"
                  value={summary.deficit}
                  color="warning"
                  icon="ri-hand-coin-line"
                />
              </Col>
              <Col xxl={3} md={6} className="mb-3">
                <NumberCard
                  title="SHEEP TO PAY"
                  value={summary.totalSheepToPay}
                  color="danger"
                  icon="ri-group-line"
                />
              </Col>
            </Row>
          </>
        ))}
    </Container>
  );
};

export default Dashboard;
