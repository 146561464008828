import { useFormik } from 'formik';
import { AsyncPaginate } from 'react-select-async-paginate';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { loadRoomOptions, loadTagOptions } from '../../services/OptionLoaders';
import { multiSelectColorStyles } from '../../utils/CommonHelpers';
import { useMemo } from 'react';

const SheepForm = ({
  initialValues = {
    name: '',
    phone: '',
    gender: 'Male',
    amountPaid: 0,
    notes: '',
    confirmed: false,
    room: undefined,
    includePaymentInTotal: true,
    transport: 'Bus',
    boardedBus: false,
    bus: undefined,
    tags: [],
  },
  onSubmit,
}) => {
  const validation = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      phone: Yup.string().required('Required'),
      amountPaid: Yup.number(),
    }),
    onSubmit: (values) => {
      if (onSubmit) {
        for (let [key, val] of Object.entries(values)) {
          if (typeof val === 'string') {
            values[key] = val.replace(/[‪‬]/g, '').trim();
            if (key === 'phone') values[key] = val.replace(/[^\d+]/g, '');
          }
        }
        if (values.room) values.room = values.room.value;
        if (values.room === '') values.room = null;
        values.tags = values.tags.map((t) => t.value);
        onSubmit(values);
      }
    },
  });

  const sheepPrice = useMemo(() => {
    const configNum = Number(localStorage.getItem('sheepPrice'));
    if (!Number.isNaN(configNum)) return configNum;
    return 650;
  }, []);

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col className="mb-2" lg="6">
          <Label htmlFor="name">Name</Label>
          <Input
            id="name"
            name="name"
            placeholder="Enter name"
            value={validation.values.name}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.name && !!validation.errors.name}
          />
          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
        </Col>
        <Col className="mb-2" lg="6">
          <Label htmlFor="gender">Gender</Label>
          <Input
            id="gender"
            name="gender"
            type="select"
            value={validation.values.gender}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Input>
        </Col>
        <Col className="mb-2" lg="6">
          <Label htmlFor="phone">Phone</Label>
          <Input
            id="phone"
            name="phone"
            type="tel"
            placeholder="Enter phone number"
            value={validation.values.phone}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            invalid={validation.touched.phone && !!validation.errors.phone}
          />
          <FormFeedback type="invalid">{validation.errors.phone}</FormFeedback>
        </Col>
        <Col className="mb-2" lg="6">
          <Label htmlFor="transport">Transportation</Label>
          <Input
            id="transport"
            name="transport"
            type="select"
            value={validation.values.transport}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          >
            <option value="Bus">Bus</option>
            <option value="Car">Car</option>
          </Input>
        </Col>
      </Row>
      <Row>
        <Col className="mb-2" lg="6" md="9">
          <Label htmlFor="amountPaid">Amount Paid</Label>
          <Input
            id="amountPaid"
            name="amountPaid"
            type="number"
            placeholder="Enter amount paid"
            min={0}
            step={1}
            value={validation.values.amountPaid}
            onChange={(e) => {
              const confirmed = e.target.value >= sheepPrice;
              validation.setFieldValue('confirmed', confirmed);
              return validation.handleChange(e);
            }}
            onBlur={validation.handleBlur}
            invalid={
              validation.touched.amountPaid && !!validation.errors.amountPaid
            }
          />
          <FormFeedback type="invalid">
            {validation.errors.amountPaid}
          </FormFeedback>
        </Col>
        <Col lg="6" md="3" className="mb-2 d-flex align-items-end">
          <FormGroup switch>
            <Input
              type="switch"
              role="switch"
              id="confirmed"
              name="confirmed"
              checked={validation.values.confirmed}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
            />
            <Label htmlFor="confirmed">Payment fulfilled</Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Label htmlFor="room">Room</Label>
          <AsyncPaginate
            value={validation.values.room}
            name="room"
            inputId="room"
            placeholder="Search room by number or occupant"
            onChange={(option) => validation.setFieldValue('room', option)}
            debounceTimeout={300}
            isClearable
            additional={{
              page: 1,
              type: validation.values.gender,
            }}
            loadOptions={loadRoomOptions}
            styles={{
              option: (styles, { isDisabled }) => {
                return isDisabled
                  ? {
                      ...styles,
                      color: 'var(--bs-danger)',
                      backgroundColor: 'var(--bs-gray-200)',
                    }
                  : styles;
              },
            }}
          />
        </Col>
        <Col lg="6">
          <Label htmlFor="tags">Tags</Label>
          <AsyncPaginate
            value={validation.values.tags}
            isMulti
            name="tags"
            inputId="tags"
            placeholder="Select tags"
            onChange={(option) => validation.setFieldValue('tags', option)}
            debounceTimeout={300}
            additional={{
              page: 1,
            }}
            loadOptions={loadTagOptions}
            styles={multiSelectColorStyles}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <Label htmlFor="notes">Notes</Label>
          <Input
            id="notes"
            name="notes"
            type="textarea"
            rows="3"
            placeholder="Notes/comments"
            value={validation.values.notes}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
          />
        </Col>
      </Row>
      <div>
        <Button type="submit" color="success">
          Save
        </Button>
      </div>
    </Form>
  );
};

export default SheepForm;
