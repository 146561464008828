import { useNavigate } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import FilterList from './FilterList';
import FilterPopover from './FilterPopover';
import {
  AsyncChoiceFilter,
  ChoiceFilter,
  DateFilter,
  FieldType,
  NumberFilter,
  TextFilter,
} from './Filterables';

const Filter = ({ filters: filtersProp = [] }) => {
  const [filterPopover, setFilterPopover] = useState(false);
  const [filterValueLabels, setFilterValueLabels] = useState({});
  const navigate = useNavigate();

  const filters = useMemo(
    () =>
      filtersProp.map((f) => {
        switch (f.fieldType) {
          case FieldType.CHOICE: {
            return new ChoiceFilter(f);
          }
          case FieldType.ASYNC_CHOICE:
            return new AsyncChoiceFilter(f);
          case FieldType.NUMBER:
            return new NumberFilter(f);
          case FieldType.DATE:
            return new DateFilter(f);
          default:
            return new TextFilter(f);
        }
      }),
    [filtersProp]
  );

  const onAdd = useCallback(
    (newFilter) => {
      setFilterPopover(false);
      setFilterValueLabels((old) => ({
        ...old,
        [newFilter.key]: newFilter.valueLabel,
      }));
      navigate({
        replace: true,
        search: (prev) => ({
          ...prev,
          [newFilter.key]: {
            [newFilter.condition]: newFilter.value,
          },
        }),
      });
    },
    [setFilterPopover, navigate]
  );

  return (
    <div className="hstack gap-2">
      <Button
        color="secondary"
        id="filter-button"
        onClick={() => {
          setFilterPopover((prev) => !prev);
        }}
      >
        <i className="ri-filter-3-line me-2" />
        Filter
      </Button>
      <FilterPopover
        filters={filters}
        show={filterPopover}
        close={() => setFilterPopover(false)}
        onAdd={onAdd}
      />
      <FilterList filters={filters} filterLabels={filterValueLabels} />
    </div>
  );
};

export default Filter;
