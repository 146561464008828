import { Spinner } from 'reactstrap';

const Loader = ({ color }) => {
  return (
    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
      <Spinner color={color ?? 'primary'} />
    </div>
  );
};

export default Loader;
