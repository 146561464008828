import { Link } from '@tanstack/react-router';
import React, { useCallback } from 'react';
import { NavItem, NavLink, Nav } from 'reactstrap';
import logo from '../assets/img/logo-white.png';

const SideBar = ({ isOpen, toggle }) => {
  const onNavClick = useCallback(() => {
    if (window.innerWidth <= 576) toggle();
  }, [toggle]);

  return (
    <div className={`sidebar bg-primary${isOpen ? ' is-open' : ''}`}>
      <div className="sidebar-header text-center">
        <span color="info" onClick={toggle}>
          &times;
        </span>
        {/* <h3 className="p-3">Bootstrap Sidebar</h3> */}
        <img src={logo} alt="Logo" height="100" className="m-3 sidebar-logo" />
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          <NavItem>
            <NavLink
              onClick={onNavClick}
              tag={Link}
              to={'/dashboard'}
              className="hstack gap-2"
            >
              <i className="ri-dashboard-line fs-3" />
              <strong>Dashboard</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={onNavClick}
              tag={Link}
              to={'/registration'}
              className="hstack gap-2"
            >
              <i className="ri-survey-line fs-3" />
              <strong>Registration</strong>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={onNavClick}
              tag={Link}
              to={'/rooms'}
              className="hstack gap-2"
            >
              <i className="ri-hotel-bed-line fs-3" />
              <strong>Rooms</strong>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

export default SideBar;
