import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Filter from '../components/filter/Filter';
import { FieldType } from '../components/filter/Filterables';
import SheepForm from '../components/sheep/SheepForm';
import SheepTable from '../components/sheep/SheepTable';
import { useToastMutation } from '../hooks/useToastMutation';
import { APIClient } from '../services/ApiClient';
import { SHEEP } from '../utils/UrlHelper';
import DeleteModal from '../components/common/DeleteModal';
import ExportCSVModal from '../components/common/ExportCSVModal';
import { useSearch } from '@tanstack/react-router';
import { loadTagOptions } from '../services/OptionLoaders';
import { selectColorStyles } from '../utils/CommonHelpers';

const apiClient = new APIClient();

const SHEEP_FILTERS = Object.freeze([
  { key: 'name', label: 'Name' },
  {
    key: 'gender',
    label: 'Gender',
    fieldType: FieldType.CHOICE,
    choices: ['Male', 'Female'],
  },
  {
    key: 'boardedBus',
    label: 'In Bus',
    fieldType: FieldType.CHOICE,
    choices: [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ],
  },
  {
    key: 'confirmed',
    label: 'Payment Fulfilled',
    fieldType: FieldType.CHOICE,
    choices: [
      { value: true, label: 'True' },
      { value: false, label: 'False' },
    ],
  },
  {
    key: 'tags',
    label: 'Tag',
    fieldType: FieldType.ASYNC_CHOICE,
    loadOptionsFn: loadTagOptions,
    styles: selectColorStyles,
  },
  {
    key: 'transport',
    label: 'Transportation',
    fieldType: FieldType.CHOICE,
    choices: ['Bus', 'Car'],
  },
  { key: 'room', label: 'Room', fieldType: FieldType.NUMBER },
  { key: 'amountPaid', label: 'Amount Paid', fieldType: FieldType.NUMBER },
  { key: 'createdAt', label: 'Registration Date', fieldType: FieldType.DATE },
]);

const SheepList = () => {
  const [formModal, setFormModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedSheep, setSelectedSheep] = useState();
  const [search, setSearch] = useState('');
  const [exportModal, setExportModal] = useState(false);

  const params = useSearch({
    strict: false,
  });

  const filterParams = useMemo(() => {
    const searchParams = { ...params };
    if (params.room) {
      searchParams.room = {
        num: params.room,
      };
    }
    if (params.tags) {
      searchParams.tags = {
        id: params.tags,
      };
    }
    if (search)
      return {
        $and: [
          {
            $or: [
              { name: { $containsi: search } },
              { phone: { $containsi: search } },
            ],
          },
          { ...searchParams },
        ],
      };
    else return searchParams;
  }, [params, search]);

  const exportQueryFn = useCallback(async () => {
    let page = 1;

    const mapData = (res) =>
      res.data.map((e) => ({
        name: e.attributes.name,
        phone: e.attributes.phone,
        gender: e.attributes.gender,
        transportation: e.attributes.transport,
        amount_paid: e.attributes.amountPaid,
        payment_fulfilled: e.attributes.confirmed,
        room: e.attributes.room?.data?.attributes.num,
        tags: e.attributes.tags?.data?.map((t) => t.attributes.label).join(';'),
        registration_date: new Date(e.attributes.createdAt).toLocaleString(
          'en-GB',
          {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          }
        ),
      }));

    const ret = [];

    let res;

    do {
      res = await apiClient.get(SHEEP, {
        pagination: {
          page: page++,
          pageSize: 100,
        },
        populate: {
          room: {
            fields: ['id', 'num'],
          },
          tags: {
            fields: ['label'],
          },
        },
        sort: 'createdAt:desc',
        ...{
          filters: {
            ...(filterParams ?? {}),
          },
        },
      });

      ret.push(...mapData(res));
    } while (res.meta.pagination.pageCount > res.meta.pagination.page);

    return ret;
  }, [filterParams]);

  const toggleModal = useCallback(
    () => setFormModal((old) => !old),
    [setFormModal]
  );

  const queryClient = useQueryClient();

  const { mutate: handleSubmit } = useToastMutation({
    mutationFn: (sheep) => {
      if (!sheep) {
        return false;
      }
      if (sheep.id) {
        return apiClient.put(`${SHEEP}/${sheep.id}`, {
          data: sheep,
        });
      } else {
        return apiClient.post(SHEEP, {
          data: sheep,
        });
      }
    },
    onSuccess: (data, sheep) => {
      queryClient.invalidateQueries(['sheep', 'list']);
      if (sheep.id) {
        queryClient.invalidateQueries(['sheep', 'details', sheep.id]);
      }
    },
    onSettled: () => {
      setSelectedSheep(undefined);
    },
  });

  const { mutate: handleDelete } = useToastMutation({
    mutationFn: (sheep) => {
      if (!sheep) {
        return false;
      }
      if (sheep.id) {
        return apiClient.delete(`${SHEEP}/${sheep.id}`);
      }
    },
    onSuccess: (data, sheep) => {
      queryClient.invalidateQueries(['sheep', 'list']);
      if (sheep.id) {
        queryClient.invalidateQueries(['sheep', 'details', sheep.id]);
      }
    },
    onSettled: () => {
      setSelectedSheep(undefined);
    },
  });

  const addSheep = useCallback(() => {
    setSelectedSheep(undefined);
    setFormModal(true);
  }, [setSelectedSheep, setFormModal]);

  const editSheep = useCallback(
    (sheep) => {
      setSelectedSheep(sheep);
      setFormModal(true);
    },
    [setSelectedSheep, setFormModal]
  );

  const deleteSheep = useCallback(
    (sheep) => {
      setSelectedSheep(sheep);
      setDeleteModal(true);
    },
    [setSelectedSheep, setDeleteModal]
  );

  const actionsCol = useMemo(
    () => ({
      header: 'Action',
      id: 'action',
      cell: (cellProps) => {
        return (
          <div>
            <Button
              color="secondary"
              outline
              className="me-1"
              onClick={() => {
                const sheep = cellProps.row.original;
                editSheep(sheep);
              }}
            >
              {' '}
              <i className="ri-pencil-line" />{' '}
            </Button>
            <Button
              color="danger"
              outline
              onClick={() => {
                const sheep = cellProps.row.original;
                deleteSheep(sheep);
              }}
            >
              {' '}
              <i className="ri-delete-bin-5-line" />{' '}
            </Button>
          </div>
        );
      },
    }),
    [editSheep, deleteSheep]
  );

  return (
    <>
      <div className="hstack mb-3 gap-3">
        <div className="search-box">
          <Input
            type="text"
            size={26}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control search bg-light border-light"
            id="searchSheep"
            placeholder="Search by name or phone"
          />
          <i className="ri-search-line search-icon"></i>
        </div>
        <Filter filters={SHEEP_FILTERS} />
        <Button
          color="info"
          className="ms-auto"
          onClick={() => setExportModal(true)}
        >
          Export
        </Button>
        <Button color="primary" outline onClick={addSheep}>
          <i className="ri-add-line fs-16 me-2" />
          Add
        </Button>
      </div>

      <SheepTable actionsCol={actionsCol} search={search} />

      <Modal size="xl" isOpen={formModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {selectedSheep ? 'Edit Sheep' : 'Add Sheep'}
        </ModalHeader>
        <ModalBody>
          {formModal && (
            <SheepForm
              onSubmit={(sheep) => {
                toggleModal();
                handleSubmit(sheep);
              }}
              initialValues={selectedSheep}
            />
          )}
        </ModalBody>
      </Modal>

      <DeleteModal
        show={deleteModal}
        icon={<i className="ri-emotion-unhappy-line fs-1 text-danger" />}
        message={
          <>
            Are you sure you want to remove this poor lost sheep? <br />
            This action is not reversible.
          </>
        }
        title="Remove Sheep"
        onCloseClick={() => {
          setSelectedSheep(undefined);
          setDeleteModal(false);
        }}
        onDeleteClick={() => {
          setDeleteModal(false);
          handleDelete(selectedSheep);
        }}
      />

      <ExportCSVModal
        onCloseClick={() => setExportModal(false)}
        filename="exported_sheep.csv"
        show={exportModal}
        queryKey={['sheep', 'export']}
        queryFn={exportQueryFn}
      />
    </>
  );
};

export default SheepList;
