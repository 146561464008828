import { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Input, InputGroup, Label } from 'reactstrap';
import { FieldType } from './Filterables';

const FilterInputs = ({ filter, condition }) => {
  const [optionLabel, setOptionLabel] = useState('');
  if (!filter) {
    return null;
  }

  switch (condition) {
    case '$null':
    case '$notNull':
      return null;
    case '$between':
      if (filter.fieldType === FieldType.NUMBER)
        return (
          <InputGroup>
            <Input type="number" placeholder="Start value" name="value" required />
            <Input type="number" placeholder="End value" name="value2" required />
          </InputGroup>
        );
      if (filter.fieldType === FieldType.DATE) {
        return (
          <Flatpickr
            className="form-control"
            name="value"
            options={{
              altInput: true,
              altFormat: 'Y-m-d',
              dateFormat: 'Y-m-d',
              mode: 'range',
            }}
          />
        );
      }
      break;
    default:
      if (filter.fieldType === FieldType.CHOICE)
        return (
          <>
            <input hidden readOnly name="valueLabel" value={optionLabel} />
            <Select
              options={filter.choices.map((c) =>
                typeof c === 'string' ? { value: c, label: c } : c
              )}
              placeholder={`Select ${filter.label}`}
              name="value"
              styles={filter.styles}
              onChange={(o) => setOptionLabel(o?.label ?? '')}
            />
          </>
        );

      if (filter.fieldType === FieldType.DATE)
        return (
          <Flatpickr
            className="form-control"
            name="value"
            options={{
              altInput: true,
              altFormat: 'Y-m-d',
              dateFormat: 'Y-m-d',
            }}
          />
        );

      if (filter.fieldType === FieldType.ASYNC_CHOICE)
        return (
          <>
            <input hidden readOnly name="valueLabel" value={optionLabel} />
            <AsyncPaginate
              name="value"
              placeholder={`Select ${filter.label}`}
              debounceTimeout={300}
              additional={{
                page: 1,
              }}
              loadOptions={filter.loadOptionsFn}
              styles={filter.styles}
              onChange={(o) => setOptionLabel(o?.label ?? '')}
            />
          </>
        );
      return (
        <>
          <Label htmlFor="filter-input" hidden>
            Filter Value
          </Label>
          <Input
            type={filter.fieldType === FieldType.NUMBER ? 'number' : 'text'}
            placeholder="Enter value"
            id="filter-input"
            name="value"
          />
        </>
      );
  }
};

export default FilterInputs;
