import { useQuery } from '@tanstack/react-query';
import { CSVLink } from 'react-csv';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import Loader from './Loader';

const ExportCSVModal = ({
  show,
  onCloseClick,
  filename = 'export.csv',
  queryKey,
  queryFn,
}) => {
  const { data, isLoading } = useQuery({
    enabled: show,
    staleTime: 30 * 1000,
    queryKey,
    queryFn: queryFn,
  });
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalHeader toggle={onCloseClick}></ModalHeader>
      <ModalBody className="py-3 px-5">
        {(isLoading && <Loader />) || (
          <>
            <div className="mt-2 text-center">
              <i className="ri-file-text-line display-5 text-success"></i>
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                <h4>File ready for download</h4>
                <p className="text-muted mx-4 mb-0">
                  The data can now be downloaded as a CSV file.
                </p>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <Button
                type="button"
                color="light"
                onClick={onCloseClick}
              >
                Close
              </Button>
              <CSVLink
                data={data ?? []}
                filename={filename}
                type="button"
                onClick={onCloseClick}
                className="btn btn-success "
              >
                Download
              </CSVLink>
            </div>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ExportCSVModal;
