import { CAMP_CONFIG, LOGIN } from '../utils/UrlHelper';

const { APIClient, setAuthorization } = require('./ApiClient');

class AuthService {
  apiClient = new APIClient();

  async login(username, password) {
    const res = await this.apiClient.post(LOGIN, {
      identifier: username,
      password,
    });
    localStorage.setItem('token', res.jwt);
    localStorage.setItem('user', JSON.stringify(res.user));
    setAuthorization(res.jwt);
    try {
      await this.onLogin();
    } catch (e) {
      console.warn('Could not complete action on login:');
      console.warn(e);
    }
  }

  async onLogin() {
    const config = await this.apiClient
      .get(CAMP_CONFIG)
      .then((res) => res.data.attributes);
    localStorage.setItem('sheepPrice', config.sheepPrice);
  }

  logout() {
    setAuthorization(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }

  getUser() {
    const user = localStorage.getItem('user');
    if (user) return JSON.parse(user);
    return null;
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getToken() {
    return localStorage.getItem('token');
  }
}

export default AuthService;
