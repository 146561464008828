import axios from 'axios';
import qs from 'qs';
import { api } from '../config';

axios.defaults.baseURL = api.API_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.paramsSerializer = (params) =>
  qs.stringify(params, { encode: false });

export function setAuthorization(token) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
}

axios.interceptors.response.use(
  (resp) => resp.data ?? resp,
  function (error) {
    const messages = {
      500: 'Internal Server Error.',
      401: 'Invalid Credentials.',
      404: 'Sorry! The data you are looking for could not be found.',
    };
    return Promise.reject(messages[error.status] ?? error);
  }
);

export class APIClient {
  constructor() {
    setAuthorization(localStorage.getItem('token'));
  }

  post = axios.post;
  update = axios.patch;
  delete = axios.delete;
  put = axios.put;

  get = (url, params) => {
    return axios.get(url, { params });
  };
}
