import { APIClient } from './ApiClient';
import { ROOMS, SHEEP, TAGS } from '../utils/UrlHelper';

const apiClient = new APIClient();

export const loadRoomOptions = async (search, prevOptions, additional) => {
  const params = {};
  params.filters = {
    $and: [{ $or: [{ type: additional.type }, { type: { $null: true } }] }],
  };
  if (search) {
    const searchOr = {
      $or: [
        {
          sheep: {
            name: {
              $containsi: search,
            },
          },
        },
        {
          sheep: {
            phone: {
              $containsi: search,
            },
          },
        },
      ],
    };
    const searchNum = Number(search);
    if (!Number.isNaN(searchNum))
      searchOr.$or.push({
        num: {
          $eq: searchNum,
        },
      });
    params.filters.$and.push(searchOr);
  }

  params.pagination = {
    page: additional.page,
  };

  const res = await apiClient.get(ROOMS, {
    ...params,
    fields: ['id', 'num', 'occupants', 'maxOccupants'],
    sort: 'num',
    populate: {
      sheep: {
        fields: ['name', 'phone'],
      },
    },
  });

  const options = [];

  for (let i of res.data) {
    const value = i.id;
    const sheep = i.attributes.sheep?.data?.map(
      (s) => `${s.attributes.name}, ${s.attributes.phone}`
    );
    const label = i.attributes.num;
    const roomOption = { value, label, num: i.attributes.num };

    const occupants = i.attributes.occupants;
    const maxOccupants = i.attributes.maxOccupants;
    if (occupants >= maxOccupants) {
      roomOption.label += ' - FULL';
      roomOption.isDisabled = true;
    } else {
      roomOption.label += ` - ${occupants}/${maxOccupants}`;
    }

    if (search && Array.isArray(sheep) && sheep.length > 0) {
      const matches = [];
      for (let s of sheep) {
        if (s.toLowerCase().includes(search.toLowerCase())) {
          matches.push(s);
        }
      }
      if (matches.length > 0) roomOption.label += ` (${matches.join(', ')})`;
    }

    options.push(roomOption);
  }

  const hasMore = res.meta.pagination.pageCount > additional.page;

  return {
    options,
    hasMore,
    additional: { ...additional, page: additional.page + 1 },
  };
};

export const loadSheepOptions = async (search, prevOptions, additional) => {
  const filters = {};
  if (search) {
    filters.$or = [
      {
        name: { $containsi: search },
      },
      {
        phone: { $containsi: search },
      },
    ];
  }
  if (additional.gender === 'Male' || additional.gender === 'Female') {
    filters.gender = additional.gender;
  }
  const params = {};
  if (Object.keys(filters).length > 0) {
    params.filters = filters;
  }
  params.pagination = {
    page: additional.page,
  };
  const res = await apiClient.get(SHEEP, {
    ...params,
    fields: ['id', 'name', 'phone'],
    sort: 'name',
    populate: {
      room: {
        fields: ['num'],
      },
    },
  });

  const letterOptions = new Map();
  for (let i of res.data) {
    const value = i.id;
    const roomNum = i.attributes.room?.data?.attributes.num;
    const label = i.attributes.name + `, ${i.attributes.phone}`;
    const sheepOption = { value, label, roomNum };

    if (roomNum) {
      sheepOption.label += ` - Room ${roomNum}`;
    }

    const letter = label.charAt(0).toUpperCase();
    if (!letterOptions.has(letter)) {
      letterOptions.set(letter, []);
    }

    letterOptions.get(letter).push(sheepOption);
  }

  const options = Array.from(letterOptions.entries()).map(([l, o]) => ({
    label: l,
    options: o,
  }));

  const hasMore = res.meta.pagination.pageCount > additional.page;

  return {
    options,
    hasMore,
    additional: { ...additional, page: additional.page + 1 },
  };
};

export const loadTagOptions = async (search, prevOptions, additional) => {
  const filters = {};
  if (search) {
    filters.label = {
      $containsi: search,
    };
  }
  const params = {};
  if (Object.keys(filters).length > 0) {
    params.filters = filters;
  }
  params.pagination = {
    page: additional.page,
  };

  const res = await apiClient.get(TAGS, params);

  const options = res.data.map((t) => ({
    value: t.id,
    label: t.attributes.label,
    color: t.attributes.color ?? '#000000',
  }));

  const hasMore = res.meta.pagination.pageCount > additional.page;

  return {
    options,
    hasMore,
    additional: { ...additional, page: additional.page + 1 },
  };
};
